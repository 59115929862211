import router from './router'
import store from './store'
// import { Message } from 'element-ui'

const whiteList = [
  '/',
  '/pages/aboutus',
  '/pages/services',
  '/pages/contactus',
  '/pages/productList',
  '/pages/productListAll',
  '/pages/productInfo',
  '/pages/privacy',
  '/pages/headlight',
  '/pages/search',
  '/console/login'
]

router.beforeEach(async (to, from, next) => {
  const hasToken = store.getters.token

  console.log('store.getters.token', store.getters.token)

  if (hasToken) {
    if (to.path === '/console/login') {
      next({ path: '/console/dashboard' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/`)
    }
  }
})
