import Vue from 'vue'
import Vuex from 'vuex'
// import request from '@/utils/request'
// import createPersistedState from 'vuex-plugin-persistedstate'
import app from './modules/app'
import auth from './modules/auth.store'
import getters from './getters'
// import storage from './store.storage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    auth
  },
  getters
})
