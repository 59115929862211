<template>
  <div class="footer">
    <div class="footer-cont">
      <div class="footer-item">
        <div class="footer-info">
          <h5 class="">Address</h5>
          <p class="">Industriestraße 3</p>
          <p class="">61191 Rosbach vor der Höhe</p>
          <h5 class="">E-mail</h5>
          <p class="">info@opinstru.de</p>
          <h5 class="">Telephone</h5>
          <p class="">+49(0)6007 917 8808</p>
        </div>
      </div>

      <div class="footer-item xs-hidden md-block">
        <p class="footer-text">
          <RouterLink to="/">Home</RouterLink>
        </p>
        <p class="footer-text">
          <RouterLink to="/pages/aboutus">About Us</RouterLink>
        </p>
        <p class="footer-text">
          <RouterLink to="/pages/services">Downloads</RouterLink>
        </p>
        <p class="footer-text">
          <RouterLink to="/pages/contactus">Contact Us</RouterLink>
        </p>
      </div>

      <div class="footer-item xs-hidden md-block">
        <p class="footer-text">Sitemap</p>
        <p class="footer-text">
          <RouterLink to="/pages/privacy">Privacy Policy</RouterLink>
        </p>
      </div>

      <div class="footer-item">
        <p class="footer-text xs-hidden md-block">
          <span class="footer-italic">Stay In Touch With</span>
          <strong>OpInstruments GmbH</strong>
        </p>
        <div
          class="footer-btn footer-italic xs-hidden md-flex"
          @click="goPage('/pages/contactus?id=1')"
        >
          SUBSCRIBE
        </div>
        <div class="follow-box">
          <h5 class="follow-title xs-hidden md-block">Follow Us</h5>
          <div class="follow-links">
            <img src="@/assets/imgs/twitter.png" alt="" />
            <img src="@/assets/imgs/facebook.png" alt="" />
            <img src="@/assets/imgs/linkedin.png" alt="" />
            <img src="@/assets/imgs/instagram.png" alt="" />
            <img src="@/assets/imgs/youtobe.png" alt="" />
          </div>
        </div>
        <div class="footer-divide xs-block md-hidden"></div>
        <p class="copyright">© Copyright 2024&nbsp;&nbsp;|&nbsp;&nbsp;OpInstruments GmbH</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    goPage() {
      if (this.$route.path === '/pages/contactus') {
        this.$router.push('/pages/contactus?id=' + new Date().valueOf())
      } else {
        this.$router.push('/pages/contactus?id=' + new Date().valueOf())
      }
      // this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  padding: 100px 0 122px;
  background: #303030;

  &-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1500px;
    margin: 0 auto;
  }

  &-item {
    margin-right: 40px;
    margin-bottom: 40px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-info {
    h5 {
      font-size: 20px;
      font-weight: 300;
      font-style: italic;
      color: #999;
    }
    p {
      font-size: 20px;
      color: #c3c3c3;
      line-height: 28px;
    }

    p + h5 {
      margin-top: 50px;
    }
    h5 + p {
      margin-top: 20px;
    }
  }

  &-text {
    margin-bottom: 20px;
    font-size: 20px;
    color: #c3c3c3;

    a {
      font-size: inherit;
      color: inherit;

      &:hover {
        color: #e7191d;
      }
    }
  }

  &-italic {
    font-style: italic;
    margin-right: 12px;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    height: 72px;
    border: 1px solid #c3c3c3;
    border-radius: 8px;
    font-size: 20px;
    color: #999;
    cursor: pointer;
  }

  .follow-box {
    margin-top: 40px;
    .follow-title {
      font-size: 20px;
      font-weight: 300;
      font-style: italic;
      color: #999;
    }
    .follow-links {
      display: flex;
      margin-top: 24px;
      img {
        margin-left: 28px;
        height: 32px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .copyright {
    margin-top: 60px;
    font-size: 16px;
    color: #8c8c8c;
  }

  .footer-divide {
    width: 100%;
    height: 1px;
    margin: 60px 0 20px;
    background: #8c8c8c;
  }
}

.footer-cont {
  padding: 0 75px;
}
.footer-btn {
  width: 400px;
}
@media (min-width: 640px) {
  .footer-btn {
    width: 480px;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  // .footer {
  //   display: none;
  // }
}
@media (min-width: 1280px) {
}
@media (min-width: 1536px) {
  .footer-cont {
    padding: 0;
  }
}
</style>
