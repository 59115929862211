export default {
  cookieInfo: {
    title: 'Privacy Preference',
    text1:
      'We use cookies on this website. These cookies store information on your computer or mobile device to improve your online experience. Cookies are small text files that enable you to navigate quickly and specifically. Cookies store your preferences and give us insight into how our website is used. Google Analytics cookies also store marketing information. By clicking on the cookie you accept it. By saving the settings, you agree to our use of cookies in accordance with your preferences (if specified).',
    text2:
      'Cookies that are necessary for the normal functioning of the site. User settings, such as your chosen   language, are included. This category does not include third-party cookies.',
    text3: 'Cookies necessary for: Google products and comment module with Disqus',
    text4: 'Accept all cookies'
  },
  about: {
    title: 'About Us',
    text: [
      'Founded in 2013 and located in Frankfurt,',
      'OpInstruments GmbH is an advanced surgical instruments supplier in Germany.',
      'It specializes in the research and development, production,sales, and related services of Class 1 surgical instruments,',
      'has international advanced surgical instruments manufacturing technology and equipment, ',
      'and has international class Ⅰsurgical instruments product lines.'
    ],
    product: [
      {
        title: 'Our Main Products',
        desc: 'OpInstruments GmbH Surgical instruments involve cardiothoracic surgery, gastrointestinal surgery, general surgery, orthopedics, and other clinical departments, with more than 1,000 specifications. OpInstruments GmbH’s products have outstanding advantages, especially in the fields of cardiac surgery and minimally invasive endoscopy. At present, OpInstruments GmbH’s products have covered more than 200 hospitals in Germany, and have been exported to more than 30 countries including China, the United States, Korea, and Japan.'
      },
      {
        title: 'Our Development Philosophy',
        desc: 'With the development concept of "scientific, rigorous, pragmatic, and beyond", an international R&D team of more than 100 people, continuously improving R&D capability and manufacturing level, OpInstruments GmbH now owns more than 100 patents and software copyrights. At the same time, it has established a complete and comprehensive quality management system in strict accordance with the requirements of relevant regulations and standards, covering the whole process of quality management from product development to production, sales, and after-sales. In addition, it has passed the European Union Medical Device Regulation 2017/745, the  Act on the Implementation of Union Regulations on Medical Devices (Medizinprodukterecht-Durchführungsgesetz, MPDG), the ISO 13485:2016 medical device quality management system and other international system certifications.'
      },
      {
        title: 'Our Goal Vision',
        desc: 'OpInstruments GmbH is committed to protecting lives with technology, assisting surgeons, and benefiting patients worldwide. In the future, OpInstruments GmbH will continuously explore cutting-edge technology in the field of surgical instruments, constantly build the core advantages of OpInstruments GmbH’s products, and actively promote the innovation and application of new surgical instruments in surgery. With an international vision, combined with excellent strategic partners around the world, OpInstruments GmbH is committed to becoming the international leading brand in the "surgical instruments" area and  the leader who promotes the development of surgical instruments technology'
      }
    ],
    strategy: [
      {
        title: 'Globalization Strategy',
        text: 'OpInstruments GmbH introduces standardized products and services to the world market and concentrates its production operations in more favorable countries, resulting in an experience curve and economies of scale and the increasing share of OPI products in the global market year by year.'
      },
      {
        title: 'Omnibearing',
        text: 'Since the beginning of its globalization strategy, OpInstruments GmbH has been committed to providing clinicians worldwide with better surgical instruments.'
      },
      {
        title: 'Pluralism',
        text: 'OpInstruments GmbH continues to develop quality surgical instruments products and is highly focused on global brand operation management.'
      },
      {
        title: 'Standardization',
        text: 'OpInstruments GmbH is based in Frankfurt and radiates around the world to build the OPI innovation engine. It has a global R&D center to ensure a continuous flow of innovation. '
      }
    ],
    advantage: {
      title: 'Core Competitive Power',
      desc: 'Horizontal coverage of multiple clinical departments, vertical iteration of core products, forming a long-term competitive advantage.',
      list: [
        {
          title: '',
          desc: 'R&D technology as the core, OpInstruments GmbH continues to lay out the surgical product pipeline of "equipment + instruments + consumables" with rich technology and experience accumulation in the field of surgical instruments, and realizes the horizontal coverage of the OPI products in various clinical departments, such as cardiac surgery, neurosurgery, orthopedics, gastroenterology, plastic and aesthetic surgery, and then forms a series of complete varieties of OPI product matrix. At the same time, guided by market demand, and based on the rich product echelon, OpInstruments GmbH constantly iterates in the field of key surgical instruments to improve the clinical use value of products, and further establish the long-term competitive advantage of the company.'
        },
        {
          title:
            'With highly specialized R&D technical team and a series of leading technology patents',
          desc: 'OpInstruments GmbH has a highly specialized R&D team and R&D centers in many places around the world, and maintains good cooperative relations with a number of universities and research institutions. Through the close cooperation of many universities and industry experts, a complete, leading, and efficient research and development system was built. Under the continuous innovative research and development concept, OpInstruments GmbH has mastered a number of industry-leading comprehensive advanced technologies.'
        },
        {
          title: 'With a comprehensive production platform and outstanding cost advantage',
          desc: 'Relying on comprehensive production platform, OpInstruments GmbH has formed several relatively complete product matrices, which is conducive to attracting new customers to purchase; and can provide a wide range of products with stable high product quality, which is conducive to existing customers to ordering required products. At the same time, the complete product chain brings the integration of production, which starts from the basic raw materials to effectively reduce the production cost, and to avoid the joint impact of the purchased intermediate products due to the market supply shortage or price fluctuations, and to ensure the stability of product quality.'
        },
        {
          title: 'Accumulation of long-term and stable industry-leading partners',
          desc: 'Relying on its own technology, production strength, and customized service capabilities, OpInstruments GmbH has won the recognition of many well-known enterprises at home and abroad, and has established stable cooperative relations with many international enterprises. Relying on outstanding product quality, good delivery record, and strong technical strength, OpInstruments GmbH wins the trust of partners.'
        },
        {
          title:
            'Flexible response to different market needs with layout of multiple global production base',
          desc: "OpInstruments GmbH has a global footprint of major production sites, with existing, under construction, and planned production capacity to support the future growth of the company's core business. OpInstruments GmbH relies on complete industrial chain and product reserve, scientific and reasonable allocation of production capacity, and cooperation of multiple production base, to flexibly respond to different market needs."
        }
      ]
    },
    professional: {
      title: 'OPI Products--Specialized Development Model',
      list: [
        {
          title: 'Professional Customization',
          desc: 'OpInstruments GmbH products can be customized according to different clinical application scenarios and the actual needs of clinicians, so as to meet the diversified needs of customers in the post-pandemic age.'
        },
        {
          title: 'Professional Team',
          desc: 'In addition to a mature R&D team, OPI builds long-term stable cooperative relationships with industrial experts to satisfy international market demand and provide expert, standard, and high-quality surgical devices.'
        },
        {
          title: 'Professional Innovative Design',
          desc: 'OPI insists on creating from the global sight and perfecting the productive skill system. While exploring advanced technology in surgical instruments, OPI verifies the products properties to improve the competitive power.'
        },
        {
          title: 'Professional Stereoscopic Service',
          desc: 'OpInstruments GmbH proposes the product whole lifecycle management service model, from the perspective of customers’ needs, providing customers with complete service from sale, usage, maintenance to upgrade.'
        }
      ]
    }
  },
  service: {
    title: 'OpInstruments GmbH',
    company: 'OpInstruments GmbH',
    address: 'Industriestraße 3,61191 Rosbach vor der Höhe',
    email: 'info@opinstru.de',
    tel: '+49(0)6007 917 8808',
    fax: '+49(0)6007 917 8809'
  },
  product: {
    description: 'Description'
  }
}
