import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      hidden: true,
      title: '首页'
    }
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('@/components/MainPageTpl.vue'),
    meta: {
      hidden: true,
      title: '首页'
    },
    children: [
      {
        path: 'aboutus',
        name: 'aboutus',
        component: () => import(/* webpackChunkName: "aboutus" */ '@/views/about/AboutUs.vue'),
        meta: {
          hidden: true,
          title: '关于我们'
        }
      },
      {
        path: 'services',
        name: 'services',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/views/services/ServicesPage.vue'),
        meta: {
          hidden: true,
          title: '服务和下载'
        }
      },
      {
        path: 'contactus',
        name: 'contactus',
        component: () =>
          import(/* webpackChunkName: "contactus" */ '@/views/contactus/ContactPage.vue'),
        meta: {
          hidden: true,
          title: '联系我们'
        }
      },
      {
        path: 'productList',
        name: 'productList',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/views/product/ProductList.vue'),
        meta: {
          hidden: true,
          title: '产品列表'
        }
      },
      {
        path: 'productListAll',
        name: 'productListAll',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/views/product/ProductListAll.vue'),
        meta: {
          hidden: true,
          title: '产品列表'
        }
      },
      {
        path: 'productInfo',
        name: 'productInfo',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/views/product/ProductInfo.vue'),
        meta: {
          hidden: true,
          title: '产品详情'
        }
      },
      {
        path: 'headlight',
        name: 'headlight',
        component: () => import(/* webpackChunkName: "glasses" */ '@/views/product/Headlight.vue'),
        meta: {
          hidden: true,
          title: '眼镜'
        }
      },
      {
        path: 'privacy',
        name: 'privacyPage',
        component: () =>
          import(/* webpackChunkName: "privacy" */ '@/views/privacy/privacyPage.vue'),
        meta: {
          hidden: true,
          title: '隐私政策'
        }
      },
      {
        path: 'search',
        name: 'searchResult',
        component: () =>
          import(/* webpackChunkName: "privacy" */ '@/views/search/searchResult.vue'),
        meta: {
          hidden: true,
          title: '搜索结果'
        }
      }
    ]
  },
  {
    path: '/console/login',
    name: 'consoleLogin',
    component: () => import('@/consoleviews/adminLogin.vue'),
    meta: {
      hidden: true,
      title: '登录页面'
    }
  },
  {
    path: '/console',
    name: 'console',
    component: () => import('@/consoleviews/components/Dashboard.vue'),
    redirect: '/console/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/consoleviews/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: 'content',
        name: 'contentManage',
        component: () => import('@/consoleviews/components/emptyPage.vue'),
        meta: {
          title: '内容管理'
        },
        children: [
          {
            path: 'homeManage',
            name: 'homeManage',
            component: () => import('@/consoleviews/contentManage/homeManage.vue'),
            meta: {
              title: '首页banner管理'
            }
          },
          {
            path: 'homeBannerEdit',
            name: 'homeBannerEdit',
            component: () => import('@/consoleviews/contentManage/homeBannerEdit.vue'),
            meta: {
              hidden: true,
              title: '首页管理'
            }
          },
          {
            path: 'homeBannerAdd',
            name: 'homeBannerAdd',
            component: () => import('@/consoleviews/contentManage/homeBannerEdit.vue'),
            meta: {
              hidden: true,
              title: '首页管理'
            }
          },
          {
            path: 'homeMediaList',
            name: 'homeMediaList',
            component: () => import('@/consoleviews/contentManage/homeMediaList.vue'),
            meta: {
              title: '首页视频管理'
            }
          },
          {
            path: 'homeMediaEdit',
            name: 'homeMediaEdit',
            component: () => import('@/consoleviews/contentManage/homeMediaEdit.vue'),
            meta: {
              hidden: true,
              title: '首页视频管理'
            }
          },
          {
            path: 'homeMediaAdd',
            name: 'homeMediaAdd',
            component: () => import('@/consoleviews/contentManage/homeMediaEdit.vue'),
            meta: {
              hidden: true,
              title: '首页视频管理'
            }
          },
          {
            path: 'serviceAndDownload',
            name: 'serviceAndDownload',
            component: () => import('@/consoleviews/contentManage/serviceAndDownload.vue'),
            meta: {
              title: '服务和下载管理'
            }
          },
          {
            path: 'serviceAndDownloadEdit',
            name: 'serviceAndDownloadEdit',
            component: () => import('@/consoleviews/contentManage/serviceAndDownloadEdit.vue'),
            meta: {
              hidden: true,
              title: '服务和下载管理'
            }
          },
          {
            path: 'serviceAndDownloadAdd',
            name: 'serviceAndDownloadAdd',
            component: () => import('@/consoleviews/contentManage/serviceAndDownloadEdit.vue'),
            meta: {
              hidden: true,
              title: '服务和下载管理'
            }
          }
        ]
      },
      {
        path: 'product',
        name: 'productManage',
        component: () => import('@/consoleviews/components/emptyPage.vue'),
        meta: {
          title: '产品管理'
        },
        children: [
          {
            path: 'productList',
            name: 'consoleProductList',
            component: () => import('@/consoleviews/product/productList.vue'),
            meta: {
              title: '产品列表'
            }
          },
          {
            path: 'productEdit',
            name: 'consoleProductEdit',
            component: () => import('@/consoleviews/product/productEdit.vue'),
            meta: {
              hidden: true,
              title: '产品编辑'
            }
          },
          {
            path: 'productAdd',
            name: 'consoleProductAdd',
            component: () => import('@/consoleviews/product/productEdit.vue'),
            meta: {
              hidden: true,
              title: '产品新增'
            }
          }
        ]
      },
      {
        path: 'category',
        name: 'categoryManage',
        component: () => import('@/consoleviews/components/emptyPage.vue'),
        meta: {
          title: '分类管理'
        },
        children: [
          {
            path: 'categoryList',
            name: 'consoleCategoryList',
            component: () => import('@/consoleviews/category/categoryList.vue'),
            meta: {
              title: '分类列表'
            }
          },
          {
            path: 'categoryEdit',
            name: 'consoleCategoryEdit',
            component: () => import('@/consoleviews/category/categoryEdit.vue'),
            meta: {
              hidden: true,
              title: '分类编辑'
            }
          },
          {
            path: 'categoryAdd',
            name: 'consoleCategoryAdd',
            component: () => import('@/consoleviews/category/categoryEdit.vue'),
            meta: {
              hidden: true,
              title: '分类编辑'
            }
          }
        ]
      },
      {
        path: 'account',
        name: 'accountManage',
        component: () => import('@/consoleviews/components/emptyPage.vue'),
        meta: {
          title: '账号管理'
        },
        children: [
          {
            path: 'accountList',
            name: 'accountList',
            component: () => import('@/consoleviews/account/accountList.vue'),
            meta: {
              title: '账号列表'
            }
          },
          {
            path: 'createAccount',
            name: 'createAccount',
            component: () => import('@/consoleviews/account/createAccount.vue'),
            meta: {
              hidden: true,
              title: '创建账号'
            }
          },
          {
            path: 'editAccount',
            name: 'editAccount',
            component: () => import('@/consoleviews/account/createAccount.vue'),
            meta: {
              hidden: true,
              title: '编辑账号'
            }
          }
        ]
      },
      {
        path: 'contactList',
        name: 'contactList',
        component: () => import('@/consoleviews/contact/contactList.vue'),
        meta: {
          title: '留言列表'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  }
})

export default router
