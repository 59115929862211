import request from '@/utils/request'

/**
 * 登录
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.username - 用户名。
 * @param {string} data.password - 密码。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const backendFetchSignin = data => {
  return request({
    url: '/opi/account/login',
    method: 'post',
    data
  })
}

/**
 * 注册
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.username - 用户名。
 * @param {string} data.password - 密码。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const backendFetchSignup = data => {
  return request({
    url: '/opi/account/addAccount',
    method: 'post',
    data
  })
}

export const backendFetchAccountList = data => {
  return request({
    url: '/opi/account/getAccountListPage',
    method: 'post',
    data
  })
}

export const backendFetchEditAccount = data => {
  return request({
    url: '/opi/account/editAccount',
    method: 'post',
    data
  })
}
