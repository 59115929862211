<template>
  <div>
    <div id="headerContainer" class="header">
      <div class="logo-cont">
        <RouterLink to="/"><img src="@/assets/imgs/logo.png" class="" /></RouterLink>
      </div>

      <div class="menu">
        <div class="menu-item">
          <RouterLink to="/" class="">Home</RouterLink>
        </div>
        <div class="menu-item product-hover" @mouseover="onProductClick">
          <span>Product</span>
        </div>
        <div class="menu-item">
          <RouterLink to="/pages/aboutus" class="">About Us</RouterLink>
        </div>
        <div class="menu-item">
          <RouterLink to="/pages/services" class="">Services & Downloads</RouterLink>
        </div>
        <div class="menu-item">
          <RouterLink to="/pages/contactus" class="">Contact Us</RouterLink>
        </div>
      </div>

      <div class="header-right">
        <div class="search-select">
          <el-autocomplete
            v-model="searchText"
            :fetch-suggestions="fetchRemoteList"
            :trigger-on-focus="false"
            placeholder="search"
            @select="handleSearchSelect"
            @keydown.enter.native="handleSearchIconClick"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="handleSearchIconClick"
            ></i>
            <template slot-scope="{ item }">
              <!-- <div class="img">{{ item.value }}</div>
              <span class="addr">{{ item.address }}</span> -->
              <div class="search-item">
                <img :src="`${imgUrl}${item.imgSrc}`" alt="" class="search-item-img" />
                <div class="search-item-cont">
                  <p class="search-item-name">{{ item.name }}</p>
                  <p class="search-item-no">{{ item.name }}</p>
                </div>
              </div>
            </template>
          </el-autocomplete>
        </div>

        <div class="mini-menu">
          <el-dropdown :hide-on-click="false" trigger="click" size="medium">
            <span class="el-dropdown-link">
              <el-icon class="mini-menu-icon" name="menu" />
            </span>
            <el-dropdown-menu slot="dropdown" class="minimenu-dropdown">
              <el-dropdown-item>
                <RouterLink to="/" class="">Home</RouterLink>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-dropdown placement="top-start" trigger="click" size="medium">
                  <span class="el-dropdown-link">Product</span>
                  <el-dropdown-menu slot="dropdown" class="minimenu-dropdown">
                    <template v-for="item in categoryList">
                      <el-dropdown-item :key="item.categoryId">
                        <span class="minimenu-dropdown-category">- {{ item.categoryName }}</span>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-for="submenu in item.categoryList"
                        :key="submenu.categoryId"
                      >
                        <RouterLink
                          :to="`/pages/productList?categoryId=${submenu.categoryId}&categoryName=${submenu.categoryName}&parentName=${item.categoryName}`"
                          class="minimenu-dropdown-link"
                        >
                          {{ submenu.categoryName }}
                        </RouterLink>
                      </el-dropdown-item>
                    </template>
                    <el-dropdown-item>
                      <RouterLink to="/pages/headlight" class="minimenu-dropdown-category">
                        - Binocular Loupes & Medical Headlight
                      </RouterLink>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-dropdown-item>
              <el-dropdown-item>
                <RouterLink to="/pages/aboutus" class="">About Us</RouterLink>
              </el-dropdown-item>
              <el-dropdown-item>
                <RouterLink to="/pages/services" class="">Services & Downloads</RouterLink>
              </el-dropdown-item>
              <el-dropdown-item>
                <RouterLink to="/pages/contactus" class="">Contact Us</RouterLink>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div id="productMenu" class="product-menu" :class="{ show: !isActive }">
      <div class="product-menu-cont">
        <template v-for="item in categoryList">
          <div :key="item.categoryName" class="product-menu-label">- {{ item.categoryName }}</div>
          <ul :key="item.categoryId" class="product-menu-list">
            <li
              v-for="submenu in item.categoryList"
              :key="submenu.categoryId"
              class="product-menu-item"
            >
              <RouterLink
                :to="`/pages/productList?categoryId=${submenu.categoryId}&categoryName=${submenu.categoryName}&parentName=${item.categoryName}`"
                class=""
              >
                {{ submenu.categoryName }}
              </RouterLink>
            </li>
          </ul>
        </template>

        <div class="product-menu-label">
          <RouterLink to="/pages/headlight" class="">
            - Binocular Loupes & Medical Headlight
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductListPage } from '@/apis/product.api'
import { fetchGetCategoryList } from '@/apis/category.api'
export default {
  data() {
    return {
      imgUrl: process.env.VUE_APP_REMOTE_IMG_URL,
      searchText: '',
      productNo: '',
      defaultList: [],
      productsList: [],
      isActive: false,
      categoryList: []
    }
  },
  watch: {
    $route: {
      handler(newValue) {
        if (newValue.name !== 'home') {
          const headerDom = document.getElementById('headerContainer')
          headerDom.setAttribute('class', 'header')
        }
      },
      deep: true
    }
  },
  created() {
    fetchGetCategoryList().then(res => {
      if (res.status === 0) {
        this.categoryList = res.data
      }
    })
  },
  mounted() {
    const headerDom = document.getElementById('headerContainer')
    let currentHeight = 0
    window.addEventListener('scroll', function () {
      this.isActive = false
      const scrollTop =
        document.documentElement.scrollTop || window.scrollY || document.body.scrollTop
      if (scrollTop > 110) {
        if (scrollTop > currentHeight) {
          headerDom.setAttribute('class', 'hide header')
        } else {
          headerDom.setAttribute('class', 'header')
        }
      }
      currentHeight = scrollTop
    })

    document.addEventListener('mousemove', event => {
      const target = event.target
      if (
        !document.querySelector('#productMenu').contains(target) &&
        !document.querySelector('.product-hover').contains(target)
      ) {
        this.isActive = false
      }
    })
  },
  methods: {
    fetchRemoteList: async (val, cb) => {
      console.log(val)
      if (!val) {
        cb([])
        return
      }
      try {
        const res = await getProductListPage({ text: val, currPage: 1, pageSize: 20 })
        if (res.status === 0) {
          cb(res.data.data)
        }
      } catch (error) {
        cb([])
      }
    },
    handleSearchSelect(item) {
      console.log('search select item', item)
      this.$router.push('/pages/productInfo?id=' + item['id'])
    },
    handleSearchIconClick() {
      console.log('search icon click')
      console.log('this.searchText', this.searchText)
      if (!this.searchText) {
        return
      }
      this.$router.push('/pages/search?text=' + this.searchText)
    },
    onProductClick() {
      this.isActive = true
    },
    onProductMouseleave(event) {
      setTimeout(() => {
        const target = event.target || event.srcElement
        if (document.getElementById('productMenu').contains(target)) {
          this.isActive = true
        }
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes productHideSubMenu {
  0% {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes productShowSubMenu {
  0% {
    -webkit-transform: translate3d(0, 300px, 0) translateZ(0) scale(1);
    transform: translate3d(0, 300px, 0) translateZ(0) scale(1);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes menu-data {
  0% {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes menu-data-hide {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }
}

.header {
  padding: 0 30px;
  position: relative;
  z-index: 20;

  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  // animation: menu-data 0.5s;

  &.hide {
    animation: menu-data-hide 0.5s;
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }
  .logo-cont {
    flex-shrink: 0;
    width: 150px;
    height: 46px;

    img {
      width: 100%;
    }
  }
  .menu {
    // margin-top: 30px;
    flex: auto;
    height: 100%;
    display: flex;
    justify-content: center;

    .menu-item {
      margin-left: 50px;
      font-size: 20px;
      color: #080404;
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: 600;

      &:first-child {
        margin-left: 0;
      }

      a,
      span {
        padding-top: 30px;
        color: inherit;

        &:hover {
          color: #e7191d;
        }
      }
    }
  }
  .header-right {
    margin-top: 20px;
    display: flex;
  }
  .search-select {
    width: 200px;
    height: 34px;
    border-radius: 16px;
    border: 1px solid #080404;

    :deep(.el-autocomplete, ) {
      height: 32px;
      .el-input,
      .el-input__inner {
        height: 32px;
      }

      .el-input__suffix {
        cursor: pointer;
        .el-input__suffix-inner {
          .el-input__icon {
            transform: translateY(-3px);
          }
        }
      }
    }

    :deep(.el-input__inner) {
      border: 0;
      background-color: transparent;
    }
  }
  .mini-menu {
    display: flex;
    width: 32px;
    height: 32px;
    margin-left: 42px;
    font-size: 32px;
    color: #080404;
    cursor: pointer;

    .mini-menu-icon {
      font-size: 32px;
    }
  }
  .menu {
    display: none;
  }
}
.minimenu-dropdown {
  .el-dropdown,
  .el-dropdown-menu__item,
  .el-dropdown-link {
    font-size: 20px;
    line-height: 46px;
    a {
      color: #606266;
    }
  }
  &.el-dropdown-menu {
    font-size: 20px;
    a {
      color: #606266;
    }
  }

  &-category,
  a.minimenu-dropdown-category {
    font-size: 20px;
    font-weight: 600;
    color: #080404;
  }
  &-link {
    font-size: 20px;
    font-weight: 300;
    color: #080404;
    padding-left: 20px;
  }
}
.product-menu {
  animation: all 1s ease-linear;
  transform: translate3d(0, 0, 0) translateZ(0) scale(1);
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-top: 1px solid #999;
  position: fixed;
  top: 107px;
  left: 0;
  z-index: 18;
  cursor: default;
  overflow: hidden;

  &.show {
    transform: translate3d(0, -410px, 0) translateZ(0) scale(1);
  }

  &-cont {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &-label {
    font-size: 18px;
    color: #080404;
    font-weight: 500;
    padding: 20px 0 10px;
    cursor: default;

    a {
      color: inherit;
    }
  }

  &-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 25%;
    cursor: default;

    a {
      font-size: 16px;
      font-weight: 300;
      color: #2c3e50 !important;
      line-height: 28px;

      &:focus,
      &:active {
        color: #2c3e50 !important;
      }

      &:hover {
        color: #e71419 !important;
      }
    }
  }
}

.search-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;

  &-img {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
  }
  &-cont {
    margin-left: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    p {
      width: 100%;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-name {
    font-size: 16px;
    color: #080404;
  }
  &-no {
    font-size: 14px;
    color: #606266;
  }
}

@media (min-width: 640px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .header {
    padding: 0 10px;
    .menu {
      display: flex;

      .menu-item {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
    .mini-menu {
      display: none;
    }
  }
}
@media (min-width: 1280px) {
  .header {
    padding: 0 75px;
    .menu {
      .menu-item {
        margin-left: 50px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
@media (min-width: 1536px) {
}
</style>
