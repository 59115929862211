import request from '@/utils/request'

/**
 * 获取横幅列表。
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.flag - flag=home 首页，flag=SD Services&Download。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const getBannerList = (data = { flag: '' }) => {
  return request({
    url: '/opi/banner/getBanner',
    method: 'post',
    data
  })
}

/**
 * 新增横幅。
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.flag - flag=home 首页，flag=SD Services&Download。
 * @param {string} data.imgSrc - 图片链接。
 * @param {string} data.fileUrl - 文件链接。
 * @param {string} data.description - 描述。
 * @param {number} data.sortOrder - 序号。
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export const backendPostAddBanner = (
  data = { flag: '', imgSrc: '', fileUrl: '', description: '', sortOrder: '' }
) => {
  return request({
    url: '/opi/banner/addBanner',
    method: 'post',
    data
  })
}

/**
 * 更新横幅。
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.id - 横幅 ID。
 * @param {string} data.flag - flag=home 首页，flag=SD Services&Download。
 * @param {string} data.imgSrc - 图片链接。
 * @param {string} data.fileUrl - 文件链接。
 * @param {string} data.description - 描述。
 * @param {number} data.sortOrder - 序号。
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export const backendPostUpdateBanner = (
  data = { id: '', flag: '', imgSrc: '', fileUrl: '', description: '', sortOrder: '' }
) => {
  return request({
    url: '/opi/banner/editBanner',
    method: 'post',
    data
  })
}
