import request from '@/utils/request'

/**
 * 分类查询产品列表
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.categoryId - 分类 ID。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const getProductListBySearch = (
  data = { categoryId: '', searchVOList: [{ name: '', searchList: [] }] }
) => {
  return request({
    url: '/opi/product/getProductListBySearch',
    method: 'post',
    data
  })
}

/**
 * 获取产品详情
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.id - 产品 ID。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const getProductById = (data = { id: '' }) => {
  return request({
    url: '/opi/product/getProductById',
    method: 'post',
    data
  })
}

/**
 * 分类下查询条件
 * @param {Object} data - 请求的数据对象。
 * @param {string} data.categoryId - 二级分类ID
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const getSearchFeature = (data = { categoryId: '' }) => {
  return request({
    url: '/opi/product/getSearchFeatures',
    method: 'post',
    data
  })
}

/**
 * 获取产品列表(搜索用)
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const getProductListPage = data => {
  return request({
    url: '/opi/product/getProductListPage',
    method: 'post',
    data
  })
}

/**
 * 获取产品列表(后台用)
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const backendFetchProductListPage = data => {
  return request({
    url: '/opi/product/getBackProductListPage',
    method: 'post',
    data
  })
}

/**
 * 产品管理-添加产品(后台用)
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const backendFetchAddProduct = data => {
  return request({
    url: '/opi/product/addProduct',
    method: 'post',
    data
  })
}

/**
 * 产品管理-编辑产品(后台用)
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 */
export const backendFetchEditProduct = data => {
  return request({
    url: '/opi/product/editProduct',
    method: 'post',
    data
  })
}
