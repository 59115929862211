<template>
  <div class="home-page">
    <div class="header-contain">
      <header-cont class="header-cont" :class="{ active: isShowNavigation }" />
    </div>

    <div class="home-contain">
      <div v-if="mediaData.length" class="banner-video" @click="play">
        <video
          v-show="!isShowNavigation"
          :src="`${imgUrl}${mediaData[0].imgSrc}`"
          muted
          autoplay
          loop
          class="media-video"
        ></video>
      </div>

      <div class="banner-swiper">
        <swiper v-show="isShowNavigation" :options="swiperOption" class="banner-swiper">
          <!-- <swiper-slide>
            <video class="media-video" muted autoplay loop @click="play">
              <source src="@/assets/imgs/home-media.mp4" type="video/mp4" />
              <source src="@/assets/imgs/home-media.webm" type="video/webm" />
            </video>
          </swiper-slide> -->
          <swiper-slide v-for="(item, index) in slideData" :key="index">
            <img
              v-if="getFileType(item.imgSrc) === 'img'"
              :src="`${imgUrl}${item.imgSrc}`"
              alt=""
              class="media"
              @click="play"
            />
            <video
              v-else-if="getFileType(item.imgSrc) === 'video'"
              :src="`${imgUrl}${mediaData[0].imgSrc}`"
              muted
              autoplay
              loop
              class="media-video"
            ></video>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>

    <FooterCont class="home-footer" />

    <el-dialog
      :visible.sync="dialogVisible"
      :lock-scroll="true"
      :show-close="false"
      :modal="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      title=""
      width="90%"
      custom-class="cookie-info-dialog"
    >
      <div class="cookie-info">
        <div class="cookie-textcont">
          <h5 class="cookie-title">{{ $t('cookieInfo.title') }}</h5>
          <p class="cookie-desc">
            {{ $t('cookieInfo.text1') }}
          </p>
          <p class="checkbox-item">
            <el-checkbox v-model="isPermiss1" disabled size="large">
              <span class="label-text">{{ $t('cookieInfo.text2') }}</span>
            </el-checkbox>
          </p>
          <p class="checkbox-item">
            <el-checkbox v-model="isPermiss2" size="large">
              <span class="label-text">{{ $t('cookieInfo.text3') }}</span>
            </el-checkbox>
          </p>
          <p class="checkbox-item">
            <el-checkbox v-model="isPermiss3" size="large">
              <span class="label-text">{{ $t('cookieInfo.text4') }}</span>
            </el-checkbox>
          </p>
        </div>
        <div class="btn-group">
          <div type="info" class="btn btn-accept" @click="save">Save preferences</div>
          <div type="info" class="btn btn-notbg" @click="goPage('/main/privacy')">
            More information
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderCont from '@/components/Header.vue'
import FooterCont from '@/components/Footer.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import 'swiper/dist/css/swiper.css'

import { getBannerList } from '@/apis/banner.api'
import { getFileType } from '@/utils'

export default {
  components: {
    HeaderCont,
    FooterCont,
    swiper,
    swiperSlide
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_REMOTE_IMG_URL,
      mySwiper: null,
      activeIndex: 0,
      defaultIndex: 0,
      isShowNavigation: false,
      dialogVisible: false,
      isPermiss1: true,
      isPermiss2: false,
      isPermiss3: false,
      slideData: [
        {
          src: 'home-banner1-media.png'
        },
        {
          title: ['Hemostatic', 'Forceps'],
          text: [
            '22-3450-28,',
            'Flex Hemostatic Forceps, ',
            'DeBakey Jaws, ',
            'Without Removable Handle, ',
            'With Ratchet'
          ],
          src: 'home-banner-n1.jpg'
        },
        {
          title: ['Micro Scissors'],
          text: [
            '07-2745-18,',
            'Micro Scissors, Round Handle',
            '45° angled micro fine blades, ',
            'Length: 180 mm, 7"'
          ],
          src: 'home-banner-n2.jpg'
        }
      ],
      swiperOption: {
        direction: 'horizontal',
        autoplay: {
          delay: 5000
        },
        loop: true,

        // If we need pagination
        pagination: '.swiper-pagination',
        slidesPerView: 1,
        initialSlide: 0,
        spaceBetween: 0,
        paginationClickable: true
      },
      mediaData: []
    }
  },
  created() {
    getBannerList({ flag: 'home' }).then(res => {
      if (res.status === 0) {
        this.slideData = res.data
      }
    })

    this.fetchBannerMediaList()
  },
  mounted() {
    // window.localStorage.removeItem('OPICOOKIE')
    if (!window.localStorage.getItem('OPICOOKIE')) {
      this.dialogVisible = true
    }
  },
  methods: {
    /** @description 根据文件名称的后缀判断文件类型 */
    getFileType,
    /** @description 获取首页banner视频列表 */
    async fetchBannerMediaList() {
      try {
        const res = await getBannerList({ flag: 'homeMedia' })
        if (res.status === 0 && res.data) {
          this.mediaData = res.data

          this.isShowNavigation = !res.data.length
          return
        }
        this.isShowNavigation = true
      } catch (error) {
        console.log(error)
      }
    },
    play() {
      this.isShowNavigation = !this.isShowNavigation

      if (this.isShowNavigation) {
        this.defaultIndex = 0
      }
    },
    goPage(url) {
      this.$router.push(url)
    },
    save() {
      if (this.isPermiss1) {
        window.localStorage.setItem('OPICOOKIE', 1)
        this.dialogVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes menu-data {
  0% {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes menu-data-hide {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }
}

.home-page {
  width: 100%;
  min-height: 100vh;
  background: #000;
  position: relative;
  .header-contain {
    width: 100%;
    height: 110px;
    background: #000;
  }
  .header-cont {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    animation: menu-data-hide 0.3s linear forwards;

    &.active {
      animation: menu-data 0.3s linear forwards;
    }
  }
  .home-contain {
    position: relative;
    padding: 30px 0 60px;
  }
  .banner-video {
    width: 100%;
    max-height: 100vh;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: hidden;
  }
  .media-video {
    width: 100%;
  }
  .media {
    max-width: 100%;
    height: auto;
    // object-fit: contain;
  }

  .banner-swiper {
    width: 100%;
    max-height: 100vh;
    background: #000;
    overflow: hidden;
    position: relative;
    left: 0;
    right: 0;

    .swiper-slide {
      width: 100%;
      // height: 100vh;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .swiper-pagination {
      height: 4px;
      width: 400px;
      background-color: #4d4d4d;
      left: 50%;
      margin-left: -200px;
      bottom: 0;
      display: flex;
      flex-direction: row;
      border-radius: 2px;
    }

    :deep(.swiper-pagination-bullets) {
      .swiper-pagination-bullet {
        flex: 1;
        height: 4px;
        background-color: #fff;
        border-radius: 1px;
        margin: 0;

        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
      }
    }
  }
  // .el-checkbox {
  //   white-space: normal;
  // }
  // .el-checkbox.el-checkbox--large {
  //   height: auto;
  // }
}

.cookie-info {
  display: flex;
  flex-direction: column;

  .cookie-textcont {
    flex: auto;
  }

  .cookie-title {
    font-size: 20px;
    line-height: 28px;
    padding: 20px 0 10px;
    margin: 0;
    color: #080404;
  }
  .cookie-desc {
    font-size: 20px;
    line-height: 28px;
    padding: 6px 0 10px;
    color: rgba(156, 163, 175, 1);
    white-space: normal;
  }
  .checkbox-item {
    display: flex;
    margin-top: 20px;

    :deep(.el-checkbox) {
      white-space: normal;
      display: flex;
      align-items: center;
    }
    .label-text {
      font-size: 18px;
      height: 28px;
    }
  }
  .btn-group {
    margin-left: 26px;
    display: flex;
    flex-direction: column;

    .btn {
      width: 100%;
      // width: 288px;
      height: 64px;
      font-size: 20px;
      text-align: center;
      line-height: 60px;
      color: #080404;
      border: 2px solid rgb(31, 41, 55);
      border-radius: 6px;
      cursor: pointer;

      &.btn-accept {
        margin-bottom: 24px;
        background: rgb(31, 41, 55);
        color: #fff;
      }
    }
  }
}

:deep(.cookie-info-dialog) {
  // .el-dialog {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  // }
  .el-dialog__header {
    display: none;
  }
  // .el-dialog__body {}
}

@media (min-width: 640px) {
}
@media (min-width: 768px) {
  .home-page {
    .home-footer {
      display: none;
    }
  }
  .cookie-info {
    flex-direction: row;
    .btn-group {
      display: flex;
      flex-basis: 288px;
      flex-shrink: 0;
      justify-content: center;
    }
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
  .banner-swiper {
    .banner-swiper-slide {
      align-items: center;
    }
  }
}
@media (min-width: 1536px) {
}
</style>
