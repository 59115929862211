import { backendFetchSignin } from '@/apis/auth.api'

const state = {
  token: sessionStorage.getItem('auth/token') || '',
  userInfo: {}
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    sessionStorage.setItem('auth/token', token)
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo
  }
}

const actions = {
  async adminLogin({ commit }, { username, password }) {
    try {
      const res = await backendFetchSignin({
        name: username,
        password
      })
      if (res.status === 0) {
        commit('SET_TOKEN', res.data.token)
        commit('SET_USER_INFO', res.data.data)

        return Promise.resolve(res)
      } else {
        return Promise.reject(res)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async adminLogout({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_USER_INFO', {})
      resolve(true)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
