import Vue from 'vue'
import VueI18N from 'vue-i18n'
import en from './modules/en'
import de from './modules/de'
import zh from './modules/zh'

Vue.use(VueI18N)

const i18n = new VueI18N({
  locale: 'en',
  messages: {
    en,
    de,
    zh
  }
})

export default i18n
