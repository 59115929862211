import request from '@/utils/request'

/**
 * 获取分类列表
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export function fetchGetCategoryList() {
  return request({
    url: '/opi/productCategory/getCategoryList',
    method: 'post',
    data: {}
  })
}

/**
 * 编辑分类（后台用）
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export function backendFetchEditCategory(data) {
  return request({
    url: '/opi/productCategory/editCategory',
    method: 'post',
    data
  })
}

/**
 * 新增分类（后台用）
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export function backendFetchAddCategory(data) {
  return request({
    url: '/opi/productCategory/addCategory',
    method: 'post',
    data
  })
}

/**
 * 分级获取分类列表（后台用）
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export function backendFetchGetCategoryList(data) {
  return request({
    url: '/opi/productCategory/getCategoryListByLevel',
    method: 'post',
    data
  })
}

/**
 * 获取分类详情（后台用）
 * @param {Object} data - 请求的数据对象。
 * @returns {Promise} 表示请求的 Promise 对象。
 * */
export const backendGetCategoryById = data => {
  return request({
    url: '/opi/productCategory/getCategoryById',
    method: 'post',
    data
  })
}
