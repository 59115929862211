import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'

// create an axios instance
const service = axios.create({
  // baseURL: 'http://39.103.98.36:9002', // url = base url + request url
  // baseURL: 'https://www.opinstru.de',
  baseURL: process.env.VUE_APP_REMOTE_API_URL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // 超时时间
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['account-token'] = store.getters.token
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.status !== 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      if (res.status === 99) {
        store.dispatch('auth/adminLogout').then(() => {
          window.location.href = '/console/login'
        })
      }

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
