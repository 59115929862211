export const getFileType = fileName => {
  const suffix = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
  const imgType = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
  const videoType = [
    'mp4',
    'avi',
    'rmvb',
    'rm',
    'flv',
    '3gp',
    'mkv',
    'mov',
    'wmv',
    'asf',
    'asx',
    'dat',
    'mpg',
    'mpeg'
  ]

  if (imgType.includes(suffix)) {
    return 'img'
  }
  if (videoType.includes(suffix)) {
    return 'video'
  }
  return 'other'
}
